.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  max-width: 100%;
  height: 'auto';
}

.sign-in {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form input {
  margin-bottom: 30px; /* Add some spacing between the input and button */
  height: 30px;
  font-size: large;
  border-radius: 4px;
}

.login-form button {
  width: 60px;
  height: 40px;
  font-weight: 400;
  font-size: large;
  border-radius: 4px;
  color: white;
  background-color: #082242;
}

.form-area {
  margin-left: 5%;
}

/* Navbar container */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #333;
}

/* Navbar menu */
.navbar-menu {
  display: flex;
}

/* Navbar item */
.navbar-item {
  margin-right: 20px;
  margin-top: 15px;
}

/* Navbar link */
.navbar-link {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  transition: all 0.3s ease;
}

.navbar-link:hover {
  background-color: #555;
}

/* Dropdown container */
.dropdown {
  position: relative;
}

/* Dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-content a {
  color: #fff;
  padding: 10px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Search form */
.navbar-search {
  display: flex;
  align-items: center;
}

input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
  height: 20px;
  font-size: large;
}

.registration-select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 10px;
  height: 30px;
  font-size: medium;
}

.navbar-search button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
  font-size: medium;
  font-weight: 400;
}

.navbar-search button:hover {
  background-color: #0056b3;
}

/* Navbar logo container */
.navbar-logo {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

/* Navbar logo */
.navbar-logo img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

/* Navbar logo text */
.navbar-logo span {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
}

.registration-components-area {
  max-width: 80%;
  margin: 0 auto; /* Sets top and bottom margins to 0, and left and right margins to auto */
}

.registration-components-area {
  display: flex;
  flex-direction: column;
}

.registration-components-area > * {
  margin-bottom: 20px; /* Adjust as needed to control the spacing between forms */
}

.registration-components-area > *:not(:last-child) {
  border-bottom: 1px solid #ccc; /* Add a faint border below each form except the last one */
  padding-bottom: 20px;
}

.student-registration-area {
  position: relative;
  margin: 0 auto;
  padding: 20px;
  align-items: center;
}

.student-registration-form-group {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* Five columns for the first level */
  grid-gap: 10px;
  margin-top: 10px;
}

.student-registration-form-group.first-level .label {
  grid-column: span 1; /* Each label and input takes up one column */
}

.student-registration-form-group.first-level .label:last-child {
  grid-column: span 2; /* Last label and input takes up two columns */
}

.student-registration-form-label {
  display: flex;
  flex-direction: column;
}

.student-registration-form-label > label {
  margin-bottom: 10px;
}

.register-student-button {
  width: 15%;
  padding: 10px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: medium;
}

.register-student-button:hover {
  background-color: #0056b3;
}

.registration-header {
  font-weight: 400;
}

.address-input {
  width: 850px;
}

.subject-registration-area {
  position: relative;
  padding: 20px;
  align-items: center;
}

.subject-registration-form-group {
  display: flex;
  flex-direction: row; /* Change flex-direction to row */
  align-items: center; /* Align items vertically in the center */
}

.subject-registration-form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.subject-registration-form-label {
  margin-bottom: 5px;
}

.subject-registration-form-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.register-subject-button {
  padding: 10px;
  background-color: #007bff;
  margin-top: 15px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: medium;
}

.register-subject-button:hover {
  background-color: #0056b3;
}

.class-registration-area {
  padding: 20px;
  align-items: center;
}

.class-registration-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.class-registration-form-item {
  flex: 0 0 50%; /* Two items per row */
  padding: 0 10px; /* Add spacing between items */
}

.class-registration-form-label {
  display: block;
  margin-bottom: 5px;
}

.time-inputs {
  display: flex;
  align-items: flex-start; /* Align inputs at the top */
}

.time-inputs input {
  max-width: 50px;
  flex: 1; /* Take up remaining space */
  margin-right: 0; /* Remove right margin */
}

.class-registration-form-item {
  flex: 0 0 100%; /* Take up full width */
  padding: 0; /* Remove padding */
}

.class-registration-form-label {
  display: block;
  margin-bottom: 5px;
  margin-right: 10px; /* Add margin to separate label and input */
}

.register-class-button {
  background-color: #007bff;
  margin-top: 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: medium;
  height: 35px;
  text-align: justify;
}

.register-class-button:hover {
  background-color: #0056b3;
}

.autofill-dropdown {
  margin-left: 10%;
  position: relative;
  margin-top: 5px;
}

.autofill-dropdown ul {
  position: absolute;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: none;
}

.autofill-dropdown li {
  padding: 8px 16px;
  cursor: pointer;
}

.autofill-dropdown li:hover {
  background-color: #f4f4f4;
}

.class-select-section {
  display: flex;
  margin-left: 10%;
  width: 80%;
  border-bottom: 1px solid #ccc; /* Add a bottom border to separate the sections */
  padding-bottom: 30px; /* Add some padding to create space between the border and the content */
}

.class-list-header-container {
  display: flex;
  justify-content: center;
}

.class-list-header {
  font-weight: 400;
}

.class-students-header{
  font-weight: 400;
}

.info-table-container {
  margin: 20px auto;
  width: 80%;
  border-bottom: 1px solid #ccc; /* Add a bottom border to separate the sections */
  padding-bottom: 10px; /* Add some padding to create space between the border and the content */
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 10px;
}

.custom-table th {
  font-weight: bold;
  text-align: left;
  color: #fff;
  background-color: #000;
}

.custom-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.custom-table tbody tr:hover {
  background-color: #ddd;
}

.custom-table tbody td {
  border: none;
  white-space: nowrap;
}

.attendance-t tbody tr {
  line-height: 0.10;
}

.add-remove-header{
  font-weight: 400;
}

.add-remove-button{
  height: 30px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: medium;
  margin-right: 10px;
}

.add-remove-button:hover {
  background-color: #0056b3;
}

.open-class-list-button {
  height: 30px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: medium;
  margin-right: 10px;
}

.open-class-list-button:hover {
  background-color: #0056b3;
}

.display-class-info {
  color: rgb(0, 123, 255);
  font-size: larger;
  text-decoration: none;
  margin-top: -20px;
}

.display-class-info:hover {
  color: #0056b3;
  text-decoration: underline;
}

.class-info{
  margin-bottom: 20px;
}

.badge {
  background-color: rgb(52, 58, 64);
  color: rgb(255, 255, 255);
  border-radius: 4px; /* Increase border radius for a larger badge */
  min-width: 10px; /* Adjust min-width as needed */
  display: inline-flex;
  justify-content: center; /* Center the text horizontally */
  align-items: center; /* Center the text vertically */
  padding: 4px 8px; /* Adjust padding as needed */
}

.badge-text {
  font-weight: bold;
  font-size: 0.8em; /* Adjust font size as needed */
}

.class-info-section {
  display: flex;
  align-items: center;
}

.class-info-values{
  padding-right: 10px;
}

.print-class-list-button{
  height: 30px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: medium;
}

.print-class-list-button:hover {
  background-color: #0056b3;
}

.navbar-search {
  position: relative;
  display: block;
}

.navbar-search input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.navbar-search button {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px; /* Adjusted width */
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  width: 200px;
  z-index: 1;
}

.dropdown-menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: calc(100% + 4px); /* Position the dropdown below the input */
  left: 0; /* Align the dropdown with the input */
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100%; /* Make the dropdown menu width same as input */
  border: 1px solid #ccc; /* Add border for dropdown */
  border-top: none; /* Remove top border */
}

.dropdown-menu ul li {
  padding: 8px 12px;
  cursor: pointer;
  display: block; /* Set list items to display as block */
  border-bottom: 1px solid #ccc; /* Add bottom border to separate items */
}

.dropdown-menu ul li:last-child {
  border-bottom: none; /* Remove bottom border for last item */
}

.dropdown-menu ul li:hover {
  background-color: #f0f0f0;
}

.search-button {
  display: inline-block;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #0056b3;
}

.student-link {
  text-decoration: none; /* Makes sure the link doesn't have an underline by default */
  color: black; /* Sets the link color to black, adjust as needed */
  font-weight: 0;
}

.student-link:hover {
  text-decoration: underline; /* Adds an underline on hover */
}

.attendance-container {
  display: flex;
  flex-direction: column; /* Ensure elements stack vertically */
  align-items: center; /* Center children horizontally */
  height: 100vh;
}

.attendance-header {
  font-weight: 400;
  text-align: center;
  margin-top: -10px;
}

.dropdown-container {
  margin-top: 20px; /* Adjust this margin as needed */
  margin-left: 10%;
  width: 80%;
  border-bottom: 1px solid #ccc; /* Add a bottom border to separate the sections */
  padding-bottom: 30px; /* Add some padding to create space between the border and the content */
}

.table-container {
  margin-top: 30px; /* Add margin to create space between the bottom border of the select dropdown and the table */
}

.mark-all-section {
  display: flex;
  align-items: center; /* Align items vertically */
}

.mark-all-section h3 {
  margin-right: 20px; /* Add some spacing between the header and the switch */
}

.input-container {
  display: flex;
  margin-top: -15px;
}

.save-attendance-button {
  margin-top: 53px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
  font-size: medium;
  font-weight: 400;
}

.save-attendance-button:hover {
  background-color: #0056b3;
}

.attendance-section {
  display: flex;
  flex-direction: column;
  min-width: 500px;
  margin-top: -30px;
}

.attendance-output {
  flex-grow: 1; /* Ensure this section takes up remaining space */
}

.dropdown-container,
.table-container {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px; /* Add padding to create space between the bottom border and content */
}

.table-container {
  margin-bottom: 20px; /* Add margin bottom for spacing */
}

.selection-container {
  display: inline-flex;
}

.print-attendance-button{
  height: 40px;
  width: 80px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: medium;
}

.print-attendance-button:hover {
  background-color: #0056b3;
}

.smaller-badge {
  padding: 0.5px 0.5px; /* Adjust padding to make the badge smaller */
  font-size: 11px; /* Adjust font size to make the text smaller */
}

.present-badge{
  background-color: rgb(40, 167, 69);
}
.missing-badge{
  background-color: rgb(255, 193, 7);
}
.attendance-badge{
  background-color: rgb(23, 162, 184);
}

.table-container {
  margin-bottom: 0; /* Remove margin bottom to eliminate the space */
  border-bottom: none; /* Remove the bottom border */
}

.move-section-container {
  display: flex;
  align-items: flex-start; /* Align items at the top */
  justify-content: space-between;
  margin-top: -50px; /* Adjust the margin to push the section up */
  margin-left: 100px; /* Adjust the margin to align with the table */
  margin-bottom: 20px;
}

.edit-profile-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  min-width: 400px;
  border: 1px solid #ccc; /* Add border */
  border-radius: 4px;
}

.divider {
  width: 400px;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}

.section-title {
  margin-left: 5%;
  font-weight: bold;
  margin-bottom: 10px;
}

.input-row {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.button-container {
  align-self: flex-end;
  margin-top: 20px;
}

.address-input {
  width: 90%;
  padding: 8px;
  margin-top: 4px;
}

/* Remove redundant styles */
.edit-profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white overlay */
  backdrop-filter: blur(5px); /* Apply blur effect to content behind the overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.autocomplete-dropdown-container {
  position: relative;
  z-index: 9999;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item--active {
  background-color: #f0f0f0;
}
  
/* StudentInfoBox component styling */
.student-info-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
  margin-left: 5px; /* Add margin from the left */
  margin-top: 5px; /* Add margin from the top */
  z-index: 1;
  background-color: white;
  padding-top: 10px;
  width: 450px;
}

.student-info-box {
  margin-left: 5px; /* Add margin from the left */
  margin-top: 5px; /* Add margin from the top */
}

.student-info-box h2 {
  margin-top: 0;
}

.edit-header {
  padding-top: 1%;
  margin-left: 5%;
}

.button-container {
  margin-left: 100px;
  margin-bottom: 10px;
}

.save-profile {
  margin-left: 30px;
}

.close-button {
  margin-left: 87%;
}

.edit-profile-container button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
}

.edit-profile-container button:hover {
  background-color: #0056b3;
}

.edit-profile-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items at the start */
  background-color: white;
  min-width: 400px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 100%;
  overflow-y: auto;
}

.divider {
  width: 100%; /* Set the width to 100% */
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}

.full-name-field,
.email-field,
.parent-ph-field,
.student-ph-field,
.grade-field,
.monthly-bill-field {
  width: 90%; /* Adjust width as needed */
  padding: 8px;
  margin-top: 4px;
}

.grade-field{
  height: fit-content;
}

.student-info-wrapper {
  padding: 5px; /* Adjust padding for space around the content */
  margin-left: 5px; /* Add margin from the left */
  margin-top: 5px; /* Add margin from the top */
}

.student-info-box h1 {
  margin-top: 0;
  font-weight: bold;
  margin-left: 5%;
}

.student-info-box p {
  margin-top: 0;
  padding-left: 20px;
}

.grade-badge {
  display: inline-block;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: -10px;
  margin-left: 5%;
  background-color: rgb(52, 58, 64);
  color: rgb(255, 255, 255);
  border-radius: 4px; /* Increase border radius for a larger badge */
  max-width: 25px; /* Adjust min-width as needed */
  display: inline-flex;
  justify-content: center; /* Center the text horizontally */
  align-items: center; /* Center the text vertically */
  font-size: x-large;
}

.student-info-box p {
  margin: 5px 0;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}

.payments-button,
.activate-button,
.edit-button,
.siblings-button {
  margin-top: 5px;
  padding: 8px 16px;
  background-color: rgb(52, 58, 64);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.payments-button:hover,
.activate-button:hover,
.edit-button:hover,
.siblings-button:hover {
  background-color: #0056b3;
}

.student-page {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure the container takes the full height of the viewport */
}

.student-page-top-half {
  display: flex;
  height: 57vh;
  background-color: rgb(233, 236, 239); /* Grey background color */
  padding: 10px 20px; /* Adjust padding (top and bottom, left and right) */
  box-sizing: border-box; /* Include padding in the height calculation */
  overflow-y: auto; /* Add scrollbar if content overflows vertically */
  gap: 50px;
}

.button-row {
  display: flex;
  justify-content: center; /* Center items horizontally */
  gap: 20px;
}

.student-info-container {
  margin-left: 50px;
}

#map {
  height: 390px;
  width: 50%;
  margin-top: 15px;
}

.class-list-section{
  width: 40%;
}

.student-page-bottom-half {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.current-present-absent-container {
  display: flex;
  justify-content: space-between;
}

.class-list-section {
  margin: 0 80px;
}

.year-month-selects {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.year-select, .month-select {
  margin: 0 10px;
}

.sibling-payment {
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Align items vertically */
}

.sibling-payment p {
  margin-right: 10px; /* Add some margin to separate the text and input */
}

.payments-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white overlay */
  backdrop-filter: blur(5px); /* Apply blur effect to content behind the overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.close-payment-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.student-info-address{
  color: rgb(108, 117, 125);
  font-weight: 500;
  padding-bottom: 3px;
}

.student-info-box p {
  display: flex;
  justify-content: space-between;
}

.student-info-label {
  font-weight: bold;
  width: 150px; /* Adjust the width as needed */
  margin-left: 1px; /* Horizontal gap between label and value */
}

.student-info-value {
  padding-right: 25px; /* Horizontal gap between value and label */
  margin-right: 10px;
}

.toggles {
  display: flex;
  align-items: center;
  margin-top: -20px;
}

.present-toggle {
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.info-table-container > h1 {
  text-align: center;
}

.payments-due > h2 {
  text-align: center;
}

.tabs {
  display: flex;
  margin-left: 10%;
}

.tabs button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  margin-right: 10px;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.3s ease;
}

.tabs button.active {
  border-bottom-color: blue; /* Change this color to match your design */
}

.payments-made > h2 {
  text-align: center;
}

.selections {
  margin-left: 10%;
}

.email-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-top: 1.5%;
}

.email-form-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.email-form label {
  display: block;
  margin-bottom: 10px;
}

.email-form select,
.email-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.email-form textarea {
  resize: vertical; /* Allow vertical resizing of textarea */
}

.email-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.email-form button:hover {
  background-color: #0056b3;
}

.email-field{
  width: 90%;
}

.ql-editor {
  min-height: 200px; /* Adjust the height as needed */
}

.payments-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white overlay */
  backdrop-filter: blur(5px); /* Apply blur effect to content behind the overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.payments-container {
  position: relative;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 80%;
  overflow-y: auto; /* Enable vertical scrolling */
}

.payment-input-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.payment-input-row input[type="number"] {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
}

.payment-buttons {
  margin-top: 20px;
}

.toggle-history-button {
  margin-bottom: 10px;
}

.payment-history-table-container {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto; /* Enable vertical scrolling for the table */
}

.payment-history-table {
  width: 100%;
  border-collapse: collapse;
}

.payment-history-table th,
.payment-history-table td {
  border: 1px solid #ccc;
  padding: 8px;
}

.payment-history-table th {
  background-color: #f2f2f2;
}

.payments-container button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-left: 10px;
}

.payments-container button:hover {
  background-color: #0056b3;
}

.mini-badge {
  background-color: white;
  color: black;
  border-radius: 4px; /* Increase border radius for a larger badge */
  min-width: 5px; /* Adjust min-width as needed */
  display: inline-flex;
  justify-content: center; /* Center the text horizontally */
  align-items: center; /* Center the text vertically */
  padding: 2px 4px; /* Adjust padding as needed */
  margin-left: 5px;
}

.multiple-payments-section,
.register-sibling-section {
  margin-bottom: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}

.sibling-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white overlay */
  backdrop-filter: blur(5px); /* Apply blur effect to content behind the overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.sibling-container {
  position: relative; /* Ensure close button position is relative to this container */
  max-width: 600px; /* Adjust as needed */
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc; /* Add border around the container */
  border-radius: 5px;
}

.close-siblings-button {
  position: absolute; /* Position the button absolutely within the container */
  top: 10px; /* Adjust top spacing as needed */
  right: 10px; /* Adjust right spacing as needed */
  padding: 8px 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.multiple-payments-section, .register-sibling-section {
  margin-bottom: 20px; /* Add spacing between sections */
}

.multiple-payments-section button {
  float: right; /* Add spacing between sections */
  margin-bottom: 10px;
}

.register-sibling-section label {
  width: 150px; /* Adjust label width as needed */
  display: inline-block;
}

.register-sibling-section input[type="text"],
.register-sibling-section input[type="number"],
.register-sibling-section input[type="date"], 
.register-sibling-section select {
  width: calc(100% - 150px); /* Adjust input width as needed */
  margin-bottom: 10px; /* Add spacing between inputs */
}

.register-sibling-section button[type="submit"] {
  float: right; /* Align button to the right */
}

.sibling-container button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-left: 10px;
}

.sibling-container button:hover {
  background-color: #0056b3;
}

.print-and-dropdowns {
  display: flex;
  align-items: center;
}

.print-section {
  margin-left: 80%;
}

@media print {
  tfoot {
    display: table-row-group; /* Keep the footer within the table structure */
  }

  tr:last-child {
    page-break-after: avoid; /* Avoid page break after the last row */
  }

  /* Hide all but the last total row */
  tfoot tr:not(:last-of-type) {
    display: none;
  }
}

.print-total-section {
  display: flex;
  gap: 80%;
}

.faint-border {
  border-top: 1px solid #ccc;
  width: 90%;
  margin: 5px 0;
}

.class-management-section{
  display: flex;
  align-items: center;
  margin-right: 64%;
}

.class-section-header {
  display: flex;
  align-items: center;
}

.class-num-badge {
  display: inline-block;
  padding: 5px 10px;
  color: white;
  border-radius: 4px;
  background-color: rgb(52, 58, 64);
  color: rgb(255, 255, 255);
  margin-left: 10%;
  border-radius: 4px; /* Increase border radius for a larger badge */
  max-width: 25px; /* Adjust min-width as needed */
  display: inline-flex;
  justify-content: center; /* Center the text horizontally */
  align-items: center; /* Center the text vertically */
  font-size: x-large;
}

.class-list-section h2 {
  white-space: nowrap;
}

.email-icon,
.phone-icon {
  color: white; /* Set icon color to white */
  background-color: #007bff; /* Set background color to blue */
  padding: 5px; /* Add padding for better visual appearance */
  border-radius: .25rem; 
  height: 10px;
}

.email-icon:hover,
.phone-icon:hover {
  background-color: #0056b3; /* Change background color on hover */
}

.icon {
  padding-left: 5px;
  font-size: 15px; /* Adjust the font size of the icon */
}

.header {
  margin-top: 10px;
  display: flex;
  align-items: center; /* Vertically center items */
  justify-content: center; /* Horizontally center items */
  flex-wrap: wrap; /* Allow items to wrap if they overflow */
}

.logo {
  margin-right: 20px;
}

.logo img {
  max-width: 150px; /* Adjust size as needed */
  height: 150px;
}

.mentors-header-info {
  margin-right: 20px;
}

.mentors-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.mentors-info p {
  margin-right: 20px;
}

.icons {
  height: 16px;
  width: 16px;
}

.icon-text-wrapper {
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 5px;
}

.autofill-dropdown.add-remove-area {
  margin-bottom: 10px; /* Adjust the margin bottom as needed */
}

.add-remove-area input[type="text"] {
  margin-bottom: 5px; /* Adjust the margin bottom as needed */
}

.add-remove-area button {
  margin-top: 5px; /* Adjust the margin top as needed */
}

/* CSS for highlighting the active tab */
.active {
  background-color: #007bff; /* Change the background color to highlight */
}

.mentors-header{
  text-align: center;
  font-size: 50px;
}

@media print {
  /* Hide unnecessary elements */
  .toggles,
  .print-attendance-button,
  .navbar,
  .attendance-header,
  .move-section-container,
  .class-info {
    display: none;
  }
  .mentors-header {
    font-size: 40px;
  }
  .logo img {
    height: 50px;
  }
  .registration-select {
    height: 25px;
    font-size: 8px;
  }
  .mentors-header-info { 
    font-size: 10px;
  }
  .mentors-header-info p {
    margin-top: -20px;
    padding-bottom: 5px;
    margin-left: 20px;
  }
  .mentors-info {
    margin-top: -20px;
    font-size: 10px;
  }
  .dropdown-container {
    border: none;
    padding-bottom: 0;
  }
  .custom-table.attendance-t {
    margin-top: -20px;
    border-bottom: none;
    border: 0.5px solid;
    width: 600px;
    margin-right: 100px;
  }
  /* Adjust font size for better printing */
  .custom-table.attendance-t thead th,
  .custom-table.attendance-t tbody td {
    font-size: 10px; /* Adjust font size as needed */
    padding: 0;
    border: 0.01px solid yellow;
    text-indent: 10px;
  }
  /* Reduce space between rows */
  .custom-table.attendance-t tbody tr {
    height: 5px !important; /* Allow the height to adjust based on content */
    line-height: 1; /* Ensure no extra space between rows */
    margin: -10px; /* Reset margin */
    padding: 0; /* Reset padding */
    border: 0.01px solid yellow;
  }
}


/* Small devices (phones, 992px and down) */
@media only screen 
    and (width: 430px) 
    and (height: 932px) 
    and (-webkit-device-pixel-ratio: 3) {
  body {
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    display: inline-flex;
  }
  .save-attendance-button {
    margin-top: 63px;
  }
}

.button-status-container {
  display: flex;
  align-items: center; /* Center items vertically */
  gap: 25%; /* Optional: space between button and status message */
  margin-top: 20px; /* Optional: space above this container */
}

.send-reminder-button {
  width: 15%;
  height: 7%;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: medium;
}

.custom-badge {
  background-color: #fff; /* Set badge background to white */
  color: #000; /* Set badge text color to black */
  border: 1px solid #000; /* Optional: Add a border for better visibility */
}

.custom-badge-text {
  color: #000; /* Set text inside the smaller badge to black */
}

/* Media query for iPhone 15 Pro Max */
@media only screen and (max-width: 430px) {
  .login-form button {
    width: 80px; /* Increase the width to provide more space */
    height: 40px; /* Keep the height the same */
    font-size: medium; /* Reduce font size */
    font-weight: 400;
  }
}

/* Navbar for Iphone */
@media only screen and (max-width: 430px) {
  .navbar {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .navbar-logo {
    align-self: flex-start; /* Logo at the top */
    margin-bottom: 10px;
  }

  .navbar-menu {
    display: flex;
    justify-content: center; /* Center align items */
    flex-wrap: nowrap; /* Prevent wrapping */
    width: 100%;
    margin-bottom: 10px; /* Space between menu and search */
  }

  .navbar-item {
    margin: 0 5px; /* Add horizontal spacing */
  }

  .navbar-search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .navbar-search input {
    margin-right: 5px;
  }

  .navbar-search button {
    margin-left: 5px;
  }

  .dropdown-content {
    width: auto; /* Allow dropdown to adjust to its content */
    box-sizing: border-box; /* Include padding and border in width calculation */
    margin-left: -15px;
  }
}

/* Header for Iphone */
@media only screen and (max-width: 430px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the start */
    margin-top: 10px;
    padding: 0 10px; /* Add padding to avoid edge-to-edge issues */
  }

  .logo {
    display: flex;
    align-items: center; /* Align logo and title vertically */
    margin-bottom: 5px; /* Space between logo/title and address */
  }

  .logo img {
    max-width: 100px; /* Reduce size for mobile */
    height: auto; /* Maintain aspect ratio */
  }

  .mentors-header-info {
    display: flex;
    flex-direction: column; /* Align logo and title horizontally */
    align-items: center; /* Center align items vertically */
    margin-left: 10px; /* Space between logo and title */
    width: auto; /* Allow width to adjust based on content */
  }

  .mentors-header {
    font-size: 30px; /* Reduce font size for mobile */
    margin-left: 80px; /* Space between logo and title */
    margin-top: -70px;
  }

  .mentors-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align text and icons to the start */
    margin-left: 20px;
    margin-top: -10px;
    width: 100%; /* Ensure full width to prevent wrapping */
  }

  .mentors-info p {
    margin: 5px 0; /* Space between items */
  }

  .icons {
    height: 14px; /* Adjust icon size for mobile */
    width: 14px;
  }

  .icon-text-wrapper {
    display: flex;
    align-items: center; /* Align items vertically */
    gap: 10px; /* Space between icon and text */
  }
}

/* Attendance for Iphone */
@media only screen and (max-width: 430px) {
  .attendance-container {
    padding: 0 10px;
    width: 100%;
    height: auto; /* Adjust height to fit content */
  }

  .selection-container button {
    height: 40px;
  }

  .attendance-header {
    font-size: 1.5rem; /* Adjust font size */
    margin-top: 10px;
  }

  .dropdown-container,
  .table-container {
    width: 100%;
    margin-left: 0;
    padding-bottom: 10px;
  }

  .attendance-section {
    min-width: 100%;
    margin-top: 10px;
  }

  .table-container {
    margin-top: 20px;
  }

  .save-attendance-button {
    width: 50%;
    margin-top: 20px;
    margin-left: 0;
    padding: 10px;
    font-size: medium;
  }

  .move-section-container {
    display: block; /* Stack elements vertically */
    margin: 10px 0;
    margin-left: 20px;
    width: 100%;
  }

  .move-section-container .add-remove-button {
    height: 40px;
    margin-top: 10px;
  }

  .move-section-container select {
    width: 100px;
  }

  .move-section-container .save-attendance-button {
    width: 60%;
    margin-top: 10px;
  }

  .print-attendance-button {
    width: 100%;
    font-size: medium;
  }

  .selection-container {
    margin-left: 20px;
  }

  .input-container {
    display: block; /* Stack input elements vertically */
    margin-top: 10px;
  }

  .mark-all-section {
    display: block; /* Stack elements vertically */
    margin-bottom: 10px;
  }

  .print-attendance-button {
    display: none;
  }

  .smaller-badge {
    font-size: 9px; /* Make badges smaller */
  }

  .move-student-container button {
    height: 30px;
  }

  .move-student-container select {
    margin-top: 10px;
  }

  .autofill-dropdown {
    margin-left: 0px;
  }

  .mark-all-section {
    display: flex; /* Display elements in a row */
    justify-content: space-between; /* Distribute space between elements */
    align-items: center; /* Vertically align items */
    margin-bottom: 10px;
    font-size: 12px;
  }

  .present-toggle {
    display: flex; /* Display elements in a row */
    justify-content: space-between; /* Distribute space between elements */
    align-items: center; /* Vertically align items */
    margin-bottom: 10px;
    font-size: 12px;
  }

}

/* Class List for Iphone */
@media only screen and (max-width: 430px) {
  .add-remove-area {
    margin-left: 40px;
  }
  .class-list-table .info-table-container table {
    margin-left: 0;
    width: 100%;
  }

  .class-list-table .info-table-container table th,
  .class-list-table .info-table-container table td {
    display: none; /* Hide all columns by default */
  }

  /* Show only the 1st, 3rd, and 4th columns */
  .class-list-table .info-table-container table td:nth-child(1),
  .class-list-table .info-table-container table th:nth-child(1),
  .class-list-table .info-table-container table td:nth-child(3),
  .class-list-table .info-table-container table th:nth-child(3),
  .class-list-table .info-table-container table td:nth-child(4),
  .class-list-table .info-table-container table th:nth-child(4) {
    display: table-cell; /* Display the specific columns */
  }

  .info-table-container button {
    display: none;
  }
  
  .add-remove-area input {
    width: 70%;
  }

  .add-remove-area button {
    height: 30px;
  }

  .class-select-section button {
    height: 40px;
    margin-left: 10px;
  }
}

/* Active Student List for Iphone */
@media only screen and (max-width: 430px) {
  .active-students .info-table-container table td:nth-child(4),
  .active-students .info-table-container table th:nth-child(4){
    display: none; /* Display the specific columns */
  }
}

/* Registration for Iphone */
@media only screen and (max-width: 430px) {
  .student-registration-area {
    padding: 0 10px; /* Add some padding to the sides */
  }

  .student-registration-form-group {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    margin-bottom: 10px; /* Add some space between groups */
  }

  .student-registration-form-label {
    width: 100%;
    margin-bottom: 10px; /* Space between labels and inputs */
  }

  .student-registration-form-label label {
    display: block; /* Ensure labels take full width */
    margin-bottom: 5px; /* Space between label and input */
  }

  .student-registration-form-label input,
  .student-registration-form-label select,
  .student-registration-form-label .address-input,
  .calendar-container{
    width: 100%; /* Full width for inputs, selects, and button */
    box-sizing: border-box; /* Include padding and border in width */
    height: 30px;
  }

  .calendar-container {
    margin-top: 5px; /* Space above calendar input */
  }

  .register-student-button {
    margin-top: 20px; /* Space above the button */
    padding: 10px; /* Add padding for a larger button */
    font-size: 1rem; /* Adjust font size */
  }

  .autocomplete-dropdown-container {
    width: 100%; /* Ensure the dropdown width matches the input */
  }

  .register-student-button {
    width: 100%;
  }

  .subject-registration-area {
    padding: 0 10px; /* Add padding to the sides */
  }

  .subject-registration-form-group {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    margin-bottom: 10px; /* Add space between form items */
  }

  .subject-registration-form-item {
    margin-bottom: 10px; /* Space between items */
  }

  .subject-registration-form-label {
    display: block; /* Ensure labels take full width */
    margin-bottom: 5px; /* Space between label and input */
  }

  .subject-registration-form-input {
    width: 100%; /* Full width for inputs */
    box-sizing: border-box; /* Include padding and border in width */
    padding: 10px; /* Add padding for a taller input */
    height: 40px; /* Set a specific height for inputs */
    font-size: 1rem; /* Adjust font size */
  }

  .register-subject-button {
    width: 80%; /* Full width for the button */
    font-size: 1rem; /* Adjust font size */
    margin-top: 10px; /* Space above the button */
  }

  .class-registration-area {
    padding: 0 10px; /* Add padding to the sides */
  }

  .class-registration-form {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    margin-bottom: 10px; /* Add space between form items */
  }

  .form-group {
    margin-bottom: 10px; /* Space between items */
  }

  .class-registration-form-label {
    display: block; /* Ensure labels take full width */
    margin-bottom: 5px; /* Space between label and input */
  }

  .registration-select,
  .time-inputs input,
  .form-group input {
    width: 100%; /* Full width for inputs and selects */
    box-sizing: border-box; /* Include padding and border in width */
    padding: 10px; /* Add padding for a comfortable input size */
    height: 40px; /* Set a specific height for inputs */
    font-size: 1rem; /* Adjust font size */
  }

  .time-inputs {
    display: flex;
    gap: 0;
    width: 300px;
  }

  .time-inputs input {
    flex: 1;
    min-width: 60px;
    margin-right: 5px; /* Space between start and end time inputs */
    font-size: 1rem;
  }

  .time-inputs input:last-child {
    margin-right: 0; /* Remove margin from the last input */
  }

  .register-class-button {
    width: 80%; /* Full width for the button */
    align-self: center;
    font-size: 1rem; /* Adjust font size */
    margin-top: 10px; /* Space above the button */
    text-align: center;
    height: 40px;
  }
}

/* Student Page for Iphone */
@media only screen and (max-width: 430px) {
  .student-page .student-page-top-half {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
  }

  .student-page .student-page-top-half #map {
    width: 100%;
    height: 200px; /* Adjust height as needed */
  }

  .student-page .student-info-box {
    width: 100%; /* Make the StudentInfoBox smaller */
    min-width: 400px; /* Set a maximum width */
    margin-left: -20px
    /* Add any other styling adjustments as needed */
  }

  .student-page .student-page-top-half #map {
    display: none;
  }

  .student-page-bottom-half {
    padding: 10px; /* Add padding for better spacing */
    display: flex;
    flex-direction: column;
  }

  /* Class Management Section */
  .student-page-bottom-half .class-management-section {
    margin-bottom: 20px; /* Space between Class Management and other sections */
  }

  .student-page-bottom-half .class-management-section .year-select {
    margin-top: 10px; /* Space above the select box */
  }

  /* Faint Border */
  .student-page-bottom-half .faint-border {
    margin: 20px 0; /* Space above and below the border */
    height: 1px; /* Thin border */
    background-color: #ccc; /* Light grey color for the border */
  }

  /* Current, Present, and Absent Classes Container */
  .student-page-bottom-half .current-present-absent-container {
    display: flex;
    flex-direction: column;
  }

  /* Each Class List Section */
  .student-page-bottom-half .current-present-absent-container .class-list-section {
    margin-bottom: 20px; /* Space between class list sections */
  }

  /* Class Section Header */
  .student-page-bottom-half .class-list-section .class-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Space below the header */
  }

  /* Tables */
  .student-page-bottom-half .current-present-absent-container .class-list-section .custom-table {
    width: 100%; /* Full width of the container */
    border-collapse: collapse; /* Collapse borders */
  }

  .student-page-bottom-half .current-present-absent-container .class-list-section .custom-table th,
  .student-page-bottom-half .current-present-absent-container .class-list-section .custom-table td {
    padding: 10px; /* Padding inside table cells */
    text-align: left; /* Align text to the left */
  }


  /* Month Select Box */
  .student-page-bottom-half .current-present-absent-container .class-list-section .month-select {
    margin-top: 10px; /* Space above the month select box */
  }

  /* Optional: Adjust the select box to full width */
  .student-page-bottom-half .registration-select {
    width: 100%;
    box-sizing: border-box; /* Include padding and border in width */
  }

  .class-management-header{
    width: 400px;
    margin-left: 400px;
    display: block;
  }

  .class-management-section {
    display: block;
  }

  .year-select {
    width: 200px;
    margin-left: 400px;
  }

  .month-select {
    width: 500px;
  }

  .current-present-absent-container {
    margin-left: 350px;
  }
}

@media (max-width: 430px) {
  .sibling-container {
    width: 90vw; /* Reduce width to fit better on smaller screens */
    max-height: 90vh; /* Limit the height to prevent overflow */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    padding: 10px;
    box-sizing: border-box; /* Ensure padding does not cause overflow */
  }

  .close-siblings-button {
    top: 5px;
    right: 5px;
    padding: 5px 8px;
  }

  .multiple-payments-section button,
  .register-sibling-section button[type="submit"] {
    width: 100%;
    float: none;
    margin: 10px 0;
  }

  .register-sibling-section label {
    width: 100%;
    display: block;
  }

  .register-sibling-section input[type="text"],
  .register-sibling-section input[type="number"],
  .register-sibling-section input[type="date"],
  .register-sibling-section select {
    width: calc(100% - 20px); /* Adjust width to fit within padding */
  }

  .registration-date-sibling {
    height: 40px;
    margin-bottom: 10px;
  }
}

